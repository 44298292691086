/* For use in src/lib/core/theming/_palette.scss */
/* Generated with: http://mcg.mbitson.com */
/* https://drive.google.com/file/d/1v1KK_2fHY9q8wD88wcmCBFuSkgqO_5VE/view?usp=sharing */
/* import into the campl-ngx-theme.scss file */
$mat-campllight: (
    50 : #e3ecee,
    100 : #b9d1d4,
    200 : #8bb2b8,
    300 : #5c939b,
    400 : #397b85,
    500 : #166470, /*main colour*/
    600 : #135c68,
    700 : #10525d,
    800 : #0c4853,
    900 : #063641,
    A100 : #77e3ff,
    A200 : #44d8ff,
    A400 : #11cdff,
    A700 : #00c3f7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

