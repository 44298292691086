@import "~@angular/material/theming";
// Plus imports for other components in your app.
// https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import "project-light.theme.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $campl-app-primary: mat-palette($mat-brown,  700, 400, 800 );

$campl-app-primary: mat-palette($mat-campllight, 500, 400, 800);
$campl-app-accent: mat-palette($mat-campllight, A200, A100, A400);

// The warn palette is optional (defaults to red).
$campl-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$campl-app-theme: mat-light-theme($campl-app-primary, $campl-app-accent, $campl-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($campl-app-theme);

.highlight_card {
    // Get a specific hue for a palette.
    // See https://material.io/archive/guidelines/style/color.html#color-color-palette for hues.
    background-color: mat-color($campl-app-accent, 200);

    // Get a relative color for a hue ('lighter' or 'darker')
    outline-color: mat-color($campl-app-accent, lighter);

    // Get a contrast color for a hue by adding `-contrast` to any other key.
    border-color: mat-color($campl-app-primary, "100-contrast");

    //color: mat-color($primary);
    color: white;
    //font-style: ;
}

.formfield_error {
    color: red;
    border-color: red;
}

.mat-grid-tile .mat-figure {
    justify-content: flex-start !important ;
    align-items: flex-start !important;
}

/* Arrows for column sorting */
.mat-sort-header-arrow {
    color: #fff !important;
    opacity: 1 !important;
}

/*
    Print the dialog content
*/
@media print {
.cdk-overlay-pane {
    width: 100%;
    max-width: none;
  }
  
  mat-dialog-container {
   width: 100vw;
   height: 100%;
   min-height: 100vh;
   padding: 0;
   margin: 0;
  }
  
  /* To avoid printing the background of a modal 
     add the no-print css class to the body element*/
  body.no-print * {
    visibility:hidden;
  }

  /* For our project summaries */
  #summary, #summary * {
    visibility:visible;
  }
  #summary {
    position:absolute;
    left:0;
    top:0;
  }
}